<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      Enquetes
    </h1>

    <div class="grid lg:grid-cols-2 items-start">
      <div class="flex flex-col md:flex-row md:items-end gap-4">
        <UISelectChauffeur
          v-model="filters.user_id"
          class="md:max-w-xs"
          label
          nullable
        />
        <UIInput
          v-model="filters.klant_id"
          type="number"
          class="md:max-w-xs"
          label="Klant ID"
          placeholder="Klant ID ..."
        />
        <button class="btn bg-dashboard-dark text-white" :disabled="!touched" @click="getData">
          Zoeken
        </button>
      </div>
    </div>

    <ul v-if="data?.profiles" class="flex flex-row gap-2 mt-4">
      <template v-for="bedrijfThema of data.profiles">
        <li v-for="(link, platform) of data.profiles[bedrijfThema]" :key="`${bedrijfThema}-${platform}`">
          <ExternalLink :href="link" class="btn hover:bg-gray-50 hover:shadow-lg">
            {{ platform.toUpperCase() }} {{ bedrijfThema }}
          </ExternalLink>
        </li>
      </template>
    </ul>

    <div class="py-4">
      <UITableResponsive :items="items" :headers="['Rit_bekijken', 'Info', 'Scores', 'Opmerkingen']">
        <template #colgroup>
          <colgroup>
            <col class="w-0">
            <col class="w-0">
            <col class="w-0">
            <col class="w-auto">
          </colgroup>
        </template>
        <template #item-Rit_bekijken="{ item }">
          <div class="flex flex-col gap-1">
            <span v-if="item.klant_id"><strong>Klant ID:</strong> {{ item.klant_id }}</span>
            <LabelPremiumRit v-if="item.kost_luxe_vervoer > 0" class="h-6" />
            <ButtonRitInfo
              :id="item.rit_id"
              class="h-6"
              prefix="Rit"
            />
          </div>
        </template>
        <template #item-Info="{ item }">
          <ul class="flex flex-col">
            <li class="pb-1">
              <b>Via {{ item.bron }}</b> &mdash; {{ dateTimeStringLocale(item.creation_date) }}
            </li>
            <li><strong>{{ item.enkele_rit ? 'Chauffeur' : 'Heenreis' }}:</strong> {{ store.getters.chauffeur(item.chauffeur_heen)?.label }}</li>
            <li v-if="!item.enkele_rit">
              <strong>Retour:</strong> {{ store.getters.chauffeur(item.chauffeur_retour)?.label }}
            </li>
          </ul>
        </template>
        <template #item-Scores="{ item }">
          <div class="flex flex-col gap-2 items-between">
            <p :class="colorClasses(item.score_heen)" class="border border-dashboard-dark p-1 px-2 whitespace-nowrap flex flex-row justify-between gap-2">
              <span>{{ item.enkele_rit ? 'Enkel' : 'Heenrit' }}</span> <span class="text-right">{{ item.score_heen }} / 10</span>
            </p>
            <p v-if="!item.enkele_rit" :class="colorClasses(item.score_retour)" class="border border-dashboard-dark p-1 px-2 whitespace-nowrap flex flex-row justify-between gap-2">
              <span>Terugrit</span> <span class="text-right">{{ item.score_retour }} / 10</span>
            </p>
          </div>
        </template>
        <template #item-Opmerkingen="{ item }">
          <div class="flex flex-col gap-2" style="min-width: 350px;">
            <p class="border border-dashboard-dark block p-1 pr-8 break-words whitespace-normal">
              {{ item.opmerking_heen || '/' }}
            </p>
            <p v-if="!item.enkele_rit" class="border border-dashboard-dark block p-1 pr-8 break-words whitespace-normal">
              {{ item.opmerking_retour || '/' }}
            </p>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination
        v-model:limit="limit"
        v-model:offset="offset"
        class="mt-2"
        :count="count"
      />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent, ref } from 'vue'
import { useStore } from 'vuex'

import ButtonRitInfo from '@/components/UI/Button/RitInfo.vue'
import ExternalLink from '@/components/UI/ExternalLink.vue'
import UIInput from '@/components/UI/Input/Input.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'

import { dateTimeStringLocale } from '@/functions/formatDate'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const LabelPremiumRit = defineAsyncComponent(() => import('@/components/UI/Labels/PremiumRit.vue'))
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const store = useStore()
const filters = ref({
  user_id: useRouteQueryInt('user_id', null),
  klant_id: useRouteQueryInt('klant_id', null),
})

const { limit, offset, data, count, items, touched, getData } = usePaginatedApi('/api/dashboard/enquetes/list', filters)

const colorClasses = score => {
  if (score >= 8) return 'bg-green-500 text-white font-bold'
  if (score < 5) return 'bg-red-500 text-white font-bold'
  return 'bg-yellow-400 text-white font-bold'
}
</script>
